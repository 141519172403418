
.error {
    display: none;
    position: absolute;
    top: 65%;
    left: 4%;
}
.nameBlock, .phoneBlock {
    position: relative;
}
.nameBlock .errorTriangle,
.phoneBlock .errorTriangle {
    content: ''; 
    position: absolute;
    left: 15px; bottom: 18px;
    border: 10px solid transparent;
    border-bottom: 12px solid #D9D9D9;
} 
.nameBlock:hover .error, .phoneBlock:hover .error {
    z-index: 10000000;
    display: inline;
    padding: 4px 14px 6px 14px;
    border-radius: 4px;
    background-color: #D9D9D9;
    color: #e10000;
    font-size: 12px;
}
#name:focus+.error, #phone:focus+.error {
    display: none;
}
.formBlock  .errorInput {
    border-color: #e10000;
}
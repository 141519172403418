.flex{
    display: flex;
    flex-direction: row;
}
.stretching  {
    flex: 1;
}

.index {
    display: flex;
    width: 100vw;
    align-items: flex-start;
    flex-flow: row;
}
.offer h1, .blockHeader {
    color: #00508D;
}
.offer h1 {
    display: inline-block;
    margin-bottom: 18px;
    margin-top: 60px;
    font-size: 88px;
    font-weight: 700;
    line-height: 110%;
}
h2.blockHeader {
    max-width: 575px;
    margin-bottom: 28px;
    font-size: 42px;
    line-height: 110%;
    font-weight: 500;
    color: #006cbf;
}

button:hover {
    cursor: pointer;
}
/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 950px) {
    .offer h1 {
        font-size: 64px;
        margin-top: 30px;
    }
    h2.blockHeader {
        max-width: 450px;
        font-size: 32px;
        line-height: 35px;
        margin-bottom: 26px;
    }
}
/* Medium-Little */
@media (max-width: 1366px) or (max-height: 850px) {
    .offer h1 {
        margin-top: 40px;
        margin-bottom: 18px;
        font-size: 55px;
    }
    h2.blockHeader {
        width: 330px;
        margin-bottom: 24px;
        font-size: 24px;
        line-height: 26px;
    }
}
/* Little */
@media (max-width: 666px) or (max-height: 720px) {
    .offer h1 {
        font-size: 44px;
    }
    h2.blockHeader {
        margin-bottom: 25px;
        font-size: 20px;
    } 
}
/* Short Height */
@media (max-height: 685px) {
    .offer h1 {
        margin-top: 0;
    }
}

/* Mobile */
@media (max-width: 570px) {
    .index {
        align-items: start;
    }
    .offer h1 {
        margin-top: 43px;
        margin-bottom: 12px;
        font-size: 46px
    }
    h2.blockHeader {
        margin-bottom: 32px;
        font-size: 24px;
    }
}
/* Experiment Mobile */
@media (max-width: 570px) {
    .index {
        align-items: start;
    }
    .offer h1 {
        margin-top: 8vw;
        margin-bottom: 12px;
        font-size: 8.5vw;
        line-height: 120%;
    }
    h2.blockHeader {
        width: 61.2vw;
        margin-bottom: 5.9vw;
        font-size: 4.4vw;
        line-height: 4.9vw;
    }
}
/* Little Mobile */
/* @media (max-width: 520px) {
    .offer h1 {
        margin-top: 20px;
        font-size: 38px;
    }
    h2.blockHeader {
        font-size: 26px;
        width: 350px;   
    }
} */
/* Very Little Mobile */
@media (max-width: 400px) {
    /* .offer h1 {
        font-size: 28px;
    }
    h2.blockHeader {
        font-size: 20px;
        line-height: 23px;
        width: 100%;   
    } */
}
/* short height */
@media (max-height: 635px) {
    /* .offer h1 {
        margin-top: 0;
    } */
}
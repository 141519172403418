/* Background for index page */

.index {
    min-height: 100vh;
    background-attachment: fixed;
    background-image: 
    url("../../public/images/background.webp");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
}
/* Medium */
@media (max-width: 1500px) or (max-height: 950px) {
    .index {
        background-image: 
        url("../../public/images/medium_background.webp");
    }
}
/* 1024/768 */
@media (max-width: 1200px) or (max-height: 900px) {
    .index {
        background-image: 
        url("../../public/images/little_background.webp");
    }
}
/* Narrow */
@media (max-width: 1000px) and (min-height: 900px) {
    .index {
        background-image: 
        url("../../public/images/mobile_background.webp");
        background-position: center bottom;
    } 
}
/* Very Narrow */
@media (max-width: 800px) {
    .index {
        /* background-position-y: 20vh; */
    }
}
/* Mobile */ 
@media (max-width: 600px) { 
    .index {
        background-image: 
        url("../../public/images/mobile_background.webp");
        background-position: center bottom;
    } 
}
/* Mobile Low hight */ 
@media (max-height: 800px) and (max-width: 650px) { 
    .index {
        background-image: 
        url("../../public/images/mobile_background.webp");
        background-position: center 1vh;
    } 
}
.formBlock {
    max-width: 568px;
    padding: 36px 36px;
    border: #006cbf 1px solid;
    border-radius: 18px;
    color: #006cbf;
    background-color: white;
}
.formBlock h2 {
    margin-bottom: 20px; 
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    line-height: 120%;
}
.formBlock input {
    width: 100%;
    max-width: 600px;
    margin-bottom: 16px;
    padding: 16px 32px;
    border: #006cbf solid 1px;
    border-radius: 14px;
    font-size: 18px;
    font-family: Muller-regular;
}

.formBlock input::placeholder {
    color: #006cbf;
    opacity: 1;
}
.formBlock .formButton {
    max-width: 600px;
}

.formButton {
    width: 100%;
    padding: 18px 0;
    border-radius: 14px;
    background-color: #006cbf;
    color: white;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
}
.formButton:active {
    background-color: #005494;
}
.formButton:hover {
    background-color: #005ea7;
}
.formButton:disabled {
    cursor: default;
    background-color: #71acda;
}

.confidential {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #6799C0;
}
.confidential a {
    color:  #6799C0;
}
.nameBlock, .phoneBlock {
    position: relative;
}

/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 950px) {
    .formBlock  {
        max-width: 506px;
        padding: 31px;
        margin-top: 0;
        border-radius: 16px;
    }
    .formBlock h2 {
        font-size: 28px;
        line-height: 110%;
    }
    .confidential {
        margin-top: 16px;
        font-size: 10.7px;
    }
    .formBlock input {
        margin-bottom: 16px;
        padding: 16px 24px;
        font-size: 16px;
    }
    .formButton {
        padding: 16px;
        font-size: 20px;
    }
}
/* Little */
@media (max-width: 1366px) or (max-height: 850px) {
    .formBlock {
        max-width: 384px;
        padding: 24px;
        border-radius: 12px;
    }
    .formBlock h2 {
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 110%;
    }
    .formBlock input {
        margin-bottom: 12px;
        padding: 12px 20px;
        border-radius: 10px;
        font-size: 12px;
    }
    .formButton {
        border-radius: 10px;
        padding: 12px;
        font-size: 18px;
    }
    .confidential {
        margin-top: 14px;
        font-size: 8px;
        line-height: 100%;
    }
}
/* Mobile */
@media (max-width: 560px) {
    /* .formBlock  {
        max-width: 420px;
        box-sizing: content-box;
        padding: 28px;
        border-radius: 14px;
    }
    .formBlock  h2 {
        font-size: 24px;
        margin-bottom: 18px;
        line-height: 29px;
    }
    .formBlock  input {
        padding: 14px 24px;
        font-size: 16px;
    }
    .formButton {
        padding: 14px 0;
        margin-top: 2px;
        font-size: 14px;
    } 
    .confidential {
        width: 276px;
        font-size: 12px;
    } */
}
/* Experiment Mobile */
@media (max-width: 560px) {
    .formBlock  {
        max-width: 420px;
        box-sizing: content-box;
        padding: 5.2vw;
        /* min-width: 170px; */
        border-radius: 2.6vw;
    }
    .formBlock  h2 {    
        font-size: 4.4vw;
        margin-bottom: 3.3vw;
        line-height: 5.3vw;
    }
    .formBlock  input {
        margin-bottom: 2.2vw;
        padding: 2.6vw 4.4vw;
        border-radius: 1.9vw;
        font-size: 3vw;
    }
    .formButton {
        padding: 2.6vw 0;
        margin-top: 0.4vw;
        border-radius: 1.9vw;
        font-size: 2.6vw;
    } 
    .confidential {
        margin-top: 2.6vw;
        width: 51.1vw;
        font-size: 2.2vw;
        line-height: 2.7vw;
    }
}
/* Little Mobile */
@media (max-width: 450px) {
    /* .formBlock {
        padding: 20px;
    }
    .formBlock  h2 {
        width: 100%;
        font-size: 18px;
        line-height: 21px;
    }
    .formBlock input {
        font-size: 12px;
    }
    .confidential {
        width: 100%;
    } */
}
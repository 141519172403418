header nav ul {
    padding: 45px 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
}
.indexPageColor {
    color: #00508D;
}
.privacyPageColor {
    color: white;
    background-color: #00508D;
}

nav li {
    margin-left: 7px;
}

.logo {
    margin: 0 auto 0 0;
}
.logo a {
    display: inline-block;
}
.firmNameLogo {
    width: 143px;
}
.agencyLogo {
    width: 102px;
    margin: 0 0 8px 7px;
}
.phone {
    display: flex;
    flex-flow: row;
    align-items: center;
}
.numberPhone {
    display: flex;
    flex-direction: row;
    margin-right:14px;
    font-weight: 400;
    font-size: 24px;
}

.numberPhone p {
    margin-left: 7px;
}
.numberPhone p:last-child {
    font-weight: 700;
}
.requestPhone {
    padding-right: 90px;
    max-width: 100%;
    font-size: 12px;
}

.callButton {
    padding: 24px 32px;
    border-radius: 16px;
    font-size: 20px;
    font-weight: 700;
}
.indexPageColor .callButton {
    background-color: #00508D;
    color: white;
} 
.privacyPageColor .callButton {
    background-color: white;
    color: #00508D;
}
.indexPageColor .callButton:hover {
    background-color: #01477e;
    cursor: pointer;
}
.indexPageColor .callButton:active {
    background-color: #003e6d;
}
.privacyPageColor .callButton:hover {
    background-color: #eee;
    cursor: pointer;
}
.privacyPageColor .callButton:active {
    background-color: #ddd;
}

/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    .requestPhone {
        padding-right: 70px;
    }
    .callButton {
        font-size: 20px;
    }
}
/* Medium-Little */
@media (max-width: 1366px) or (max-height: 820px) {
    header nav ul {
        justify-content: space-between;
        padding-bottom: 39px;
    }
    .firmNameLogo {
        width: 120px;
    }
    .agencyLogo {
        margin-bottom: 5px;
        width: 90px;
    }
    .numberPhone p {
        font-size: 22px;
        margin-left: 5px;
        font-weight: 400;
    }
    .requestPhone {
        margin-left: 40px;
        padding-right: 25px;
        font-size: 13px;
    }
    .callButton {
        padding: 16px 32px;
        border-radius: 14px;
        font-size: 16px;
    }
}
/* Little */
@media (max-width: 1066px) or (max-height: 700px) {
    .firmNameLogo {
        width: 107px;
    }
    .agencyLogo {
        margin-bottom: 5px;
        width: 77px;
    }
    .numberPhone p {
        margin-left: 5px;
        font-size: 16px;
    }
    .requestPhone {
        padding-right: 25px;
        font-size: 11px;
    }
    .callButton {
        padding: 19px 22px;
        font-size: 14px;
    }
}
/* Short Width */
@media (max-width: 1200px) {
    .logo {
        margin: 0;
    }
    .numberPhone {
        display: flex;
        flex-direction: column;
    }
}
/* Short Height */
@media (max-height: 650px) {
    header nav ul {
        padding: 30px 0;
    }
}
/* Mobile */
@media (max-width: 800px) {
    header nav ul{
        padding: 31px 0;
    }
    .firmNameLogo {
        width: 100px;
    }
    .agencyLogo {
        margin: 0 0 4px 4px;
        width: 70px;
    }
    .phone {
        display: none;
    }

    .callButton {
        padding: 20px 32px;
        font-size: 16px;
    }

    .getBlock h2.blockHeader {
        display: none;
    }
}
/* Experiment mobile */
@media (max-width: 800px) {
    header nav ul{
        padding: 5.7vw 0;
    }
    .firmNameLogo {
        width: 18.5vw;
    }
    .agencyLogo {
        margin: 0 0 0.7vw 0.7vw;
        width: 13vw;
    }
    .phone {
        display: none;
    }

    .callButton {
        padding: 3.7vw 5.9vw;
        border-radius: 3vw;
        font-size: 3vw;
    }

    .getBlock h2.blockHeader {
        display: none;
    }
}
/* Little Mobile */
@media (max-width: 460px) {
    /* .firmNameLogo {
        width: 90px;
    }
    .agencyLogo {
        margin: 0 0 4px 4px;
        width: 63px;
    }
    .callButton {
        font-size: 14px;
        padding: 16px 16px;
    } */
}
/* Very Little Mobile */
@media (max-width: 390px) {
    /* .firmNameLogo {
        width: 80px;
    }
    .agencyLogo {
        margin: 0 0 4px 4px;
        width: 56px;
    }
    .callButton {
        padding: 14px 14px;
        border-radius: 12px;
        font-size: 12px;
    } */
}

.closeButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .0);
}
.closeButton:hover {
    background-color: rgba(0, 0, 0, .1);
}
.closeButton:active {
    background-color: rgba(0, 0, 0, .2);
}
/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    .closeButton {
        top: 1rem;
        right: 1rem;
    }
}
/* Mobile */
@media (max-width: 970px) {
    .closeButton {
        top: 1rem;
        right: 1rem;
    }
}
/* Little Mobile */
@media (max-width: 450px) {
    .closeButton {
        top: 0.3rem;
        right: 0.3rem;
    }
}
@keyframes openForm {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.modal {
    position: relative;
    z-index: 1000;
    margin: auto;
    padding: 102px 76px 102px 76px;
    border-radius: 20px;
    background-color: white;
    color: black;
    animation: openForm 0.7s;
}
.modal h2 {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
}

/* Success block */
.imageBlock {
    position: relative;
    margin-bottom: 52px;
    text-align: center;
}
.bottomStar, .topStar {
    width: 27px;
}
.bottomStar {
    position: absolute;
    bottom: 15%;
    left: 32%;
}
.topStar {
    position: absolute;
    top: 10%;
    left: 66%;
}
.bigStar {
    position: absolute;
    top: -18%;
    left: 60%;
}

@media (max-width: 830px) {
    .modal {
        padding: 70px 50px;
    }
    .modal h2 {
        font-size: 28px;
        line-height: 32px;
    }
    .bottomStar {
        left: 26%;
    }
    .topStar {
        left: 72%;
    }
    .bigStar {
        left: 66%;
    }
}
@media (max-width: 680px) {
    .modal h2 {
        font-size: 24px;
        line-height: 29px;
    }
    .bottomStar {
        left: 20%;
    }
    .topStar {
        top: 12%;
        left: 75%;
    }
    .bigStar {
        left: 69%;
    }
}
@media (max-width: 590px) {
    .circle {
        width: 130px;
    }
    .modal {
        padding: 70px 30px 40px 30px;
    }
    .modal h2 {
        font-size: 20px;
        line-height: 24px;
    }
    .errorBlock {
        padding-top: 40px;
    }
    .bottomStar {
        left: 14%;
    }
    .topStar {
        left: 81%;
    }
    .bigStar {
        left: 72%;
    }
}
@media (max-width: 590px) {
}
.page {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
}
/* Paddings for containers */
.container {
    padding: 0 200px;
}

/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    .container {
        padding: 0 120px;
    }
    
}
/* Little */
@media (max-width: 1066px) or (max-height: 680px) {
    .container {
        padding: 0 120px;
    }
}
/* Mobile */
@media (max-width: 920px) {
    .container {
        padding: 0 40px;
    }
}
/* Mobile Experimental */
@media (max-width: 540px) {
    .container {
        padding: 0 7.4vw;
    }
}
/* Little Mobile */
@media (max-width: 520px) {
    /* .container {
        padding: 0 20px;
    } */
}
/* Very Little Mobile */
@media (max-width: 350px) {
    /* .container {
        padding: 0 15px;
    } */
}